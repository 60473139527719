
import { License } from '@/models';
import { ApiService, LoaderService } from '@/services';
import { onMounted, defineComponent, ref } from 'vue';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { useRouter } from 'vue-router';
import { LicensesRoute } from '@/router';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
	components: {
		InputText,
		Textarea,
		Button,
		Checkbox
	},
	props: {
		licenseId: String
	},
	setup(props) {
		const router = useRouter();
		const license = ref<License>(null);
		const loadToken = LoaderService.invoke();
		const toast = useToast();

		onMounted(async () => {
			try {
				license.value = await ApiService.get(`/licenses/${props.licenseId}`);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load license. Try again later.',
					life: 3000
				});
				router.push({
					name: LicensesRoute
				});
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const submit = async () => {
			const submitLoadToken = LoaderService.invoke();

			try {
				await ApiService.put(`/licenses/${props.licenseId}`, license.value);
				toast.add({
					severity: 'success',
					summary: 'Success',
					detail: 'License saved.',
					life: 3000
				});
				router.push({
					name: LicensesRoute
				});
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to save license. Try again later.',
					life: 3000
				});
			} finally {
				LoaderService.clear(submitLoadToken);
			}
		};

		return { license, submit };
	}
});
